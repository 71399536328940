import React from "react";
import { Text } from "@mantine/core";

interface TracksWaypointsMenuPlaceholderProps {
  emptyText: string | JSX.Element;
}

const TracksWaypointsMenuPlaceholder = (props: TracksWaypointsMenuPlaceholderProps) => {
  const { emptyText } = props;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        whiteSpace: 'pre-line',
      }}
    >
      {typeof emptyText === "string" ? (
        <Text
          p="md"
          color="dimmed"
          size="sm"
          align="center"
          sx={{ userSelect: "none" }}
        >
          {emptyText}
        </Text>
      ) : (
        emptyText
      )}
    </div>
  );
};

export default TracksWaypointsMenuPlaceholder;
