import { useMediaQuery } from "@mantine/hooks";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

const MobileMenuContext = createContext(
  {} as { isMobileMenuOpen: boolean; toggleMobileMenu: () => void }
);
const useMobileMenu = () => useContext(MobileMenuContext);
const { Provider } = MobileMenuContext;

const MobileMenuProvider = (props: { children: ReactNode | undefined }) => {
  const { children } = props;

  const isSmallViewport = useMediaQuery("(max-width: 768px)");
  const [isMobileMenuOpen, setMobileMenuOpen] = useState<boolean>(
    isSmallViewport ? true : false
  );

  const toggleMobileMenu = useCallback(() => {
    setMobileMenuOpen((value: boolean) => !value);
  }, [setMobileMenuOpen]);

  const autoHideMobileMenu = useCallback(() => {
    if (window.innerWidth > 768) {
      setMobileMenuOpen(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", autoHideMobileMenu);

    return () => {
      window.removeEventListener("resize", autoHideMobileMenu);
    };
  }, []);

  return (
    <Provider value={{ isMobileMenuOpen, toggleMobileMenu }}>{children}</Provider>
  );
};

export { MobileMenuProvider, useMobileMenu };
