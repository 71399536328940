import { waypointIcons } from './icons'

export const getWaypointIconByKey = (name: string | undefined | null) => {
  if (!name) return null
  const icon = waypointIcons.find((value) => value.key.toLowerCase() === name.toLowerCase())
  if (icon) return icon.name
  return null
}

export const getWaypointIconKeyByName = (name: string) => {
  const icon = waypointIcons.find((value) => value.name.toLowerCase() === name.toLowerCase())
  if (icon) return icon.key
  return ''
}

export const getWaypointIconSrc = (name: string | undefined) => {
  const iconName = getWaypointIconByKey(name)
  if (!iconName) return "img/map_icons/map_icon_default.png"
  return `img/map_icons/${iconName}.png`
}
