import { useModals } from '@mantine/modals'
import { VerifyEmailModalType } from 'containers/UI/Modals/VerifyEmailModal'
import { useTranslation } from 'react-i18next'

export const useAuthModals = () => {
  const modals = useModals()
  const { t } = useTranslation()

  const openSignUpModal = () => {
    modals.openContextModal('signUp', {
      title: t('signup_modal.title'),
      centered: true,
      innerProps: {},
    })
  }

  const openVerifyEmailModal = (type: VerifyEmailModalType, message: string, email?: string) => {
    modals.openContextModal('verifyEmail', {
      centered: true,
      withCloseButton: false,
      innerProps: {
        type,
        message,
        email,
      },
    })
  }
  const openResetPasswordModal = () => {
    modals.openContextModal('resetPassword', {
      title: t('reset_password_modal.title'),
      centered: true,
      innerProps: {},
    })
  }

  const openSetNewPasswordModal = (key: string) => {
    modals.openContextModal('enterNewPasswordModal', {
      title: t('new_password_modal.title'),
      centered: true,
      innerProps: {
        key,
      },
    })
  }

  const openResetEmailSentModal = (text?: string) => {
    modals.openContextModal('resetEmailSent', {
      centered: true,
      withCloseButton: false,
      innerProps: {
        text,
      },
    })
  }

  return {
    openSignUpModal,
    openVerifyEmailModal,
    openResetPasswordModal,
    openResetEmailSentModal,
    openSetNewPasswordModal,
  }
}
