import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Group, Stack, Text, ThemeIcon } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";
import { useGenericModals } from "hooks/useGenericModals";
import { useLanguage } from "hooks/useLanguage";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { uninterceptedAxiosInstance } from "utils/axios/axios";

const BASE_API_URL = import.meta.env.VITE_BASE_API_URL ?? "";

export type VerifyEmailModalType = "initial" | "error";

async function resendVerifyEmail(email: string, language: string) {
  return await uninterceptedAxiosInstance.post(
    `${BASE_API_URL}/resend-verification`,
    {
      agent: "Trackbook",
      lng: language,
      email,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}

export const VerifyEmailModal = ({
  context,
  id,
  innerProps,
}: ContextModalProps<{
  type: VerifyEmailModalType;
  message: string;
  email?: string;
}>) => {
  const notVerifiedEmail = innerProps.type === "error";
  const [loading, setLoading] = useState(false);
  const { language } = useLanguage();
  const { openErrorModal, openInfoModal } = useGenericModals();
  const { t } = useTranslation();
  const signUpTitle = t("verify_email_modal.verify_title");
  const loginTitle = t("verify_email_modal.not_verified_title");

  const resendEmail = useCallback(async () => {
    try {
      if (!innerProps.email) {
        throw new Error("email_address_missing");
      }
      setLoading(true);
      await resendVerifyEmail(innerProps.email, language);

      context.closeModal(id);
      openInfoModal(
        t("verify_email_modal.email_resend_success_title"),
        t("verify_email_modal.email_resend_success_text")
      );
    } catch (error) {
      openErrorModal({
        title: t("verify_email_modal.email_resend_error_title"),
        text: t("verify_email_modal.email_resend_error_text"),
      });
    } finally {
      setLoading(false);
    }
  }, [innerProps.email, language]);

  return (
    <Stack align="center">
      <ThemeIcon
        size={70}
        variant="light"
        color={notVerifiedEmail ? "red" : "blue"}
        radius={100}
        sx={{ fontSize: 35 }}
      >
        <FontAwesomeIcon icon={faEnvelope} />
      </ThemeIcon>
      <Text size="xl" weight={700}>
        {notVerifiedEmail ? loginTitle : signUpTitle}
      </Text>
      <Text align="center">{innerProps.message}</Text>
      {notVerifiedEmail ? (
        <Group sx={{ justifyContent: "space-between" }}>
          <Button
            variant="filled"
            loading={loading}
            onClick={() => resendEmail()}
          >
            {t("verify_email_modal.resend_email")}
          </Button>
          <Button
            color="gray"
            variant="outline"
            onClick={() => context.closeModal(id)}
          >
            {t("generic.cancel_button_label")}
          </Button>
        </Group>
      ) : (
        <Button fullWidth onClick={() => context.closeModal(id)}>
          {t("generic.ok")}
        </Button>
      )}
    </Stack>
  );
};
