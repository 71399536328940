import { useState } from 'react'

import axios from 'axios'
import { useOptions } from 'stores/optionsStore/OptionsContext'

import { useAuth } from './useAuth'

const BASE_API_URL = import.meta.env.VITE_BASE_API_URL ?? ''

export const useGPXupload = () => {
  const [isUploading, setIsUploading] = useState(false)
  const { user } = useAuth()
  const {
    state: { language },
  } = useOptions()

  const isZipArchive = function(fileName: string) {
    return fileName.includes("gpz") || fileName.includes("kmz");
  };

  const upload = async (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      if (!user) throw new Error('Not signed in')
      const reader = new FileReader()
      reader.onload = async (e) => {
        let contents;

        if (isZipArchive(file.name)) {
          const cont = e.target?.result as ArrayBuffer | null | undefined;
          if (!cont) throw new Error('File empty')
          const uint8Array = new Uint8Array(cont);
          contents = window.btoa(
            uint8Array.reduce(
              (acc, current) => acc + String.fromCharCode(current),
              ""
            )
          );
        }
        else {
          contents = e.target?.result;
          if (!contents) throw new Error('File empty');
        }

        try {
          const { data } = await axios.post(
            `${BASE_API_URL}/import-tracks-file`,
            {
              file: contents,
              fileName: file.name,
              agent: 'Trackbook',
              lng: language,
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
              withCredentials: true,
            }
          )
          if (data.msg) {
            setIsUploading(false)
            resolve(data.msg as string)
          }
          reject(new Error('No data returned from server'))
        } catch (error) {
          setIsUploading(false)
          reject(error)
        }
      }

      if (isZipArchive(file.name)) {
        reader.readAsArrayBuffer(file)
      }
      else {
        reader.readAsText(file)
      }
    })

  return { upload, isUploading }
}
