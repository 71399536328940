import L from 'leaflet'

import 'leaflet-extra-markers'
import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css'
import trackpointIconImg from '../../assets/icons/track_point.png'

export const defaultIcon = L.ExtraMarkers.icon({
  icon: 'fa-circle',
  markerColor: '#CB2B3A',
  shape: 'circle',
  prefix: 'fa',
  svg: true,
})

export const nonRoutableStartIcon = L.ExtraMarkers.icon({
  icon: 'fa-circle',
  markerColor: '#05CB25',
  shape: 'circle',
  prefix: 'fa',
  svg: true,
})

export const nonRoutableEndIcon = L.ExtraMarkers.icon({
  icon: 'fa-circle',
  markerColor: 'orange',
  shape: 'circle',
  prefix: 'fa',
  svg: true,
})

export const trackPointIcon = L.icon({
  iconUrl: trackpointIconImg,
  iconSize: [12, 12],
  iconAnchor: [6, 6],
  className: 'trackPointIcon',
})

export const chartTrackIcon = L.ExtraMarkers.icon({
  icon: 'fa-circle',
  markerColor: '#E2DB12',
  shape: 'circle',
  prefix: 'fa',
  svg: true,
})

export const numericMarkerIcon = (color: `#${string}`, number: number) =>
  L.ExtraMarkers.icon({
    icon: 'fa-number',
    extraClasses: 'leaflet-num-marker',
    svg: true,
    markerColor: color,
    number: String(number),
  })
