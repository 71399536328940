import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";

import { useMatomo } from "@datapunt/matomo-tracker-react";
import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
} from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { NotificationsProvider } from "@mantine/notifications";
import { AxiosInterceptor } from "components/AxiosInterceptor";
import { MANTINE_THEME } from "config/mantine";
import { ConfirmLosingTrackModal } from "containers/UI/Modals/ConfirmLosingTrackModal";
import { CoordsInputModal } from "containers/UI/Modals/CoordsInputModal";
import { EditTrackModal } from "containers/UI/Modals/EditTrackModal";
import { EditWaypointModal } from "containers/UI/Modals/EditWaypointModal";
import { EnterNewPasswordModal } from "containers/UI/Modals/EnterNewPasswordModal";
import { ErrorModal } from "containers/UI/Modals/ErrorModal";
import { ImportGPXModal } from "containers/UI/Modals/ImportGPXModal";
import { ModalWithTopIcon } from "containers/UI/Modals/ModalWithTopIcon";
import { PremiumFeatureModal } from "containers/UI/Modals/PremiumFeatureModal";
import { ResetEmailSentModal } from "containers/UI/Modals/ResetEmailSentModal";
import { ResetPasswordModal } from "containers/UI/Modals/ResetPasswordModal";
import { SettingsModal } from "containers/UI/Modals/SettingsModal";
import { SignUpModal } from "containers/UI/Modals/SignUpModal";
import { VerifyEmailModal } from "containers/UI/Modals/VerifyEmailModal";
import { WelcomeModal } from "containers/UI/Modals/WelcomeModal";
import { Auth, AuthProvider } from "hooks/useAuth";
import { CesiumContextProvider } from "hooks/useCesiumContext";
import { useDetectMode } from "hooks/useDetectMode";
import { EditModeProvider } from "hooks/useEditMode";
import { LiftedModalContextProvider } from "hooks/useLiftedModalContext";
import { MapContextProvider } from "hooks/useMapContext";
import { PublicTracksDataProvider } from "hooks/usePublicTracks";
import { TrackStatsProvider } from "hooks/useTrackStats";
import { UserTracksDataProvider } from "hooks/useUserTracks";
import { UserWaypointDataProvider } from "hooks/useUserWaypoints";
import {
  getCacheSizePercentage,
  purgeCache,
} from "lib/leaflet-cached-tile-layer";
import { GenericContextProvider } from "stores/genericStore/GenericContext";

import { MainContainer } from "./containers/MainContainer";
import {
  mergeOptions,
  OptionsProvider,
} from "./stores/optionsStore/OptionsContext";
import { RoutingProvider } from "./stores/routingStore/RoutingContext";
import { optionsReducer } from "./stores/optionsStore/reducer";
import { initialOptionsState } from "./stores/optionsStore/state";
import {
  updateNonSaveableOptions,
  updateSaveableOptions,
} from "stores/optionsStore/actions";
import { GPXViewerAppsModal } from "containers/UI/Modals/GPXViewerAppsModal";
import { LoadingScreen } from "./LoadingScreen";
import { showErrorNotification } from "utils/notifications/customNotifications";
import { useTranslation } from "react-i18next";
import { captureMessage } from "@sentry/react";
import { useImageUpload } from "hooks/useImageUpload";
import axios from "axios";
import { MobileMenuProvider } from "hooks/useMobileMenu";

export const App = () => {
  const userRef = useRef<Auth["user"]>();
  const { enableLinkTracking } = useMatomo();
  const { mode } = useDetectMode();
  const [colorScheme, setColorScheme] = useState<ColorScheme>(
    (localStorage.getItem("colorScheme") as ColorScheme) ?? mode
  );
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  const { trackPageView } = useMatomo();
  const { t } = useTranslation();
  const { getStorageSize } = useImageUpload();
  const optionsReducerTuple = useReducer(optionsReducer, initialOptionsState);
  const [optionsState, dispatchOptions] = optionsReducerTuple;
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    trackPageView({});
  }, [trackPageView]);

  useEffect(() => {
    getCacheSizePercentage()
      .then((cacheSizePecentage) => {
        const cacheSizePercentageThreshold = 50;
        if (cacheSizePecentage !== null) {
          if (cacheSizePecentage > cacheSizePercentageThreshold) {
            purgeCache(
              import.meta.env.VITE_CACHE_DB_NAME,
              Number(import.meta.env.VITE_CACHE_DB_VERSION)
            )
              .then((success) => {
                if (success) {
                  // console.info("Tile cache purged succesfully!")
                } else {
                  throw new Error(
                    "Tile purge return value didn't return properly as true."
                  );
                }
              })
              .catch((error) => {
                console.error("Failed to purge tile cache.", error?.toString());
              });
          }
        } else {
          throw new Error("Couldn't determine cache size.");
        }
      })
      .catch((error) => {
        console.error(
          "Unable to get tile cache size so auto purging won't work.",
          error?.toString()
        );
      });
  }, []);

  const getColorScheme = useCallback(
    (color: string | undefined) => {
      if (!color) return mode;
      if (color === "dark") return "dark";
      return "light";
    },
    [mode]
  );

  const handleAuth = useCallback(
    (authInstance: Auth) => {
      const { getAppSubscriptionStatus, getUserSettings, user, signOut } =
        authInstance;

      if (user) {
        if (!userRef.current) {
          if (
            user.settings?.colorScheme &&
            colorScheme !== user.settings?.colorScheme
          ) {
            setColorScheme(getColorScheme(user.settings.colorScheme));
          }

          Promise.allSettled([
            getAppSubscriptionStatus(
              user.settings?.language ?? navigator.language
            ).then((result) => {
              const { errors, state } = result.data;

              if (state === "active") {
                dispatchOptions(
                  updateNonSaveableOptions({
                    allowPremiumFeatures: true,
                  })
                );
              } else if (state === "inGracePeriod") {
                dispatchOptions(
                  updateNonSaveableOptions({
                    allowPremiumFeatures: true,
                  })
                );
                showErrorNotification({
                  title: t("errors.subscription_in_grace_period.title"),
                  message: t("errors.subscription_in_grace_period.text"),
                });
              } else if (errors && errors.length > 0) {
                captureMessage(
                  `Trackbook: Errors when getting info about subscription status: ${errors.join(
                    " "
                  )}`
                );
              }
            }),
            getUserSettings().then((settings) => {
              const mergedOptions = mergeOptions(settings, optionsState);

              setColorScheme(getColorScheme(settings.colorScheme));
              dispatchOptions(updateSaveableOptions(mergedOptions));
            }),
            getStorageSize().then((size) => {
              dispatchOptions(
                updateNonSaveableOptions({
                  storageSize: size,
                })
              );
            }),
          ]).then((settled) => {
            setLoading(false);

            for (const response of settled) {
              if (
                response.status === "rejected" &&
                axios.isAxiosError(response.reason)
              ) {
                const error = response.reason;

                if (error.response?.status === 401) {
                  signOut();
                  break;
                } else {
                  captureMessage(error.toString());
                }
              }
            }
          });
        }

        userRef.current = user;
      } else {
        setLoading(false);
      }
    },
    [colorScheme, getColorScheme]
  );

  enableLinkTracking();

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider
        theme={{ colorScheme, ...MANTINE_THEME }}
        withNormalizeCSS
        withGlobalStyles
      >
        <NotificationsProvider autoClose={8000}>
          <LiftedModalContextProvider>
            <MobileMenuProvider>
              <MapContextProvider>
                <CesiumContextProvider>
                  <TrackStatsProvider>
                    <RoutingProvider>
                      <AuthProvider onAuth={handleAuth}>
                        <AxiosInterceptor>
                          <OptionsProvider reducerTuple={optionsReducerTuple}>
                            <GenericContextProvider>
                              <EditModeProvider>
                                <UserWaypointDataProvider>
                                  <UserTracksDataProvider>
                                    <UserWaypointDataProvider>
                                      <PublicTracksDataProvider>
                                        <ModalsProvider
                                          modals={{
                                            signUp: SignUpModal,
                                            verifyEmail: VerifyEmailModal,
                                            resetPassword: ResetPasswordModal,
                                            resetEmailSent: ResetEmailSentModal,
                                            errorModal: ErrorModal,
                                            editWaypoint: EditWaypointModal,
                                            editTrack: EditTrackModal,
                                            topIconModal: ModalWithTopIcon,
                                            importGPX: ImportGPXModal,
                                            welcome: WelcomeModal,
                                            confirmLosingTrack:
                                              ConfirmLosingTrackModal,
                                            coordsInputModal: CoordsInputModal,
                                            settingsModal: SettingsModal,
                                            premiumFeatureModal:
                                              PremiumFeatureModal,
                                            gpxViewerAppsModal:
                                              GPXViewerAppsModal,
                                            enterNewPasswordModal:
                                              EnterNewPasswordModal,
                                          }}
                                          modalProps={{
                                            centered: true,
                                            styles: (theme) => ({
                                              title: {
                                                fontWeight: 700,
                                                fontSize: theme.fontSizes.lg,
                                              },
                                              root: {
                                                fontSize: theme.fontSizes.sm,
                                              },
                                            }),
                                          }}
                                        >
                                          {loading ? (
                                            <LoadingScreen />
                                          ) : (
                                            <MainContainer />
                                          )}
                                        </ModalsProvider>
                                      </PublicTracksDataProvider>
                                    </UserWaypointDataProvider>
                                  </UserTracksDataProvider>
                                </UserWaypointDataProvider>
                              </EditModeProvider>
                            </GenericContextProvider>
                          </OptionsProvider>
                        </AxiosInterceptor>
                      </AuthProvider>
                    </RoutingProvider>
                  </TrackStatsProvider>
                </CesiumContextProvider>
              </MapContextProvider>
            </MobileMenuProvider>
          </LiftedModalContextProvider>
        </NotificationsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  );
};
