import { Routing } from 'config/enums/routings'
import { Track, TrackRaw } from 'types/app'
import { decodeBase64string, encodeBase64string } from 'utils/base64'
import { decodePolyline, encodePolyline } from 'utils/polyline'

import { parseLinksArrayToString, parseLinksStringToArray } from '../linksParser/linksParser'
import { decodeTrackPointsTime, encodeTrackPointsTime } from './trackPointsTimeParser'

interface TrackAPIwrite {
  id: number
  name: string
  desc: string
  links: string
  controlPoints: string
  trackPoints: string
  trackPointsEle: string
  color: string
  routing: string
  pub: string // API expects 'true' | 'false' strings
  trackPointsCadence: string
  trackPointsHeartRate: string
  trackPointsPower: string
  trackPointsSpeed: string
  trackPointsTemperature: string
  trackPointsTime: string
  cmt: string
  number: string
  src: string
  type: string
  visible: boolean
}

export const parseTrackForAPI = ({
  links,
  controlPoints,
  trackPoints,
  trackPointsEle,
  pub,
  id,
  name,
  desc,
  color,
  routing,
  cmt,
  number,
  src,
  type,
  trackPointsCadence,
  trackPointsHeartRate,
  trackPointsPower,
  trackPointsSpeed,
  trackPointsTemperature,
  trackPointsTime,
  visible,
}: Partial<Track>): Partial<TrackAPIwrite> => ({
  links: links && links.length > 0 ? parseLinksArrayToString(links) : '',
  controlPoints: controlPoints ? encodePolyline(controlPoints || [], 6) : '',
  trackPoints: trackPoints ? encodePolyline(trackPoints || [], 6) : '',
  trackPointsEle: trackPointsEle ? encodeBase64string(trackPointsEle || []) : '',
  pub: pub ? 'true' : 'false',
  id: id ?? -1,
  name: name ?? '',
  desc: desc ?? '',
  color: color ?? '',
  routing: routing ?? '',
  trackPointsCadence: trackPointsCadence ? encodeBase64string(trackPointsCadence) : '',
  trackPointsHeartRate: trackPointsHeartRate ? encodeBase64string(trackPointsHeartRate) : '',
  trackPointsPower: trackPointsPower ? encodeBase64string(trackPointsPower) : '',
  trackPointsSpeed: trackPointsSpeed ? encodeBase64string(trackPointsSpeed) : '',
  trackPointsTemperature: trackPointsTemperature ? encodeBase64string(trackPointsTemperature) : '',
  trackPointsTime: trackPointsTime ? encodeTrackPointsTime(trackPointsTime) : '',
  cmt: cmt ?? '',
  number: number ?? '',
  src: src ?? '',
  type: type ?? '',
  visible,
})

export const parseTrackFromAPI = ({
  id,
  name,
  desc,
  links,
  controlPoints,
  trackPoints,
  trackPointsEle,
  trackPointsCadence,
  trackPointsHeartRate,
  trackPointsPower,
  trackPointsSpeed,
  trackPointsTemperature,
  trackPointsTime,
  color,
  pub,
  routing,
  userId,
  uuid,
  cmt,
  src,
  number,
  type,
  visible
}: TrackRaw): Track => ({
  trackPoints: decodePolyline(trackPoints),
  trackPointsEle: decodeBase64string(trackPointsEle),
  controlPoints: decodePolyline(controlPoints),
  links: parseLinksStringToArray(links),
  trackPointsCadence: trackPointsCadence ? decodeBase64string(trackPointsCadence) : null,
  trackPointsHeartRate: trackPointsHeartRate ? decodeBase64string(trackPointsHeartRate) : null,
  trackPointsPower: trackPointsPower ? decodeBase64string(trackPointsPower) : null,
  trackPointsSpeed: trackPointsSpeed ? decodeBase64string(trackPointsSpeed) : undefined,
  trackPointsTemperature: trackPointsTemperature ? decodeBase64string(trackPointsTemperature) : null,
  trackPointsTime: trackPointsTime ? decodeTrackPointsTime(trackPointsTime) : null,
  id,
  name,
  desc,
  color,
  pub,
  routing: routing as Routing,
  userId,
  uuid,
  cmt,
  src,
  number,
  type,
  visible
})
