import {
  faCircleUser,
  faRightToBracket,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, PasswordInput, Stack, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { captureMessage } from "@sentry/react";
import axios, { AxiosError } from "axios";
import { SECONDARY_COLOR } from "config/constants";
import { useAuth } from "hooks/useAuth";
import { useAuthModals } from "hooks/useAuthModals";
import { useEditMode } from "hooks/useEditMode";
import { useGenericModals } from "hooks/useGenericModals";
import { useImageUpload } from "hooks/useImageUpload";
import { useLanguage } from "hooks/useLanguage";
import { useMapContext } from "hooks/useMapContext";
import { useTranslation } from "react-i18next";
import { updateNonSaveableOptions } from "stores/optionsStore/actions";
import { useOptions } from "stores/optionsStore/OptionsContext";
import { AuthAPIErrors } from "types/app";
import { showErrorNotification } from "utils/notifications/customNotifications";

export const SignInPanel = () => {
  const { signIn, signOut, getAppSubscriptionStatus } = useAuth();
  const { openSignUpModal, openVerifyEmailModal, openResetPasswordModal } =
    useAuthModals();
  const {
    updateSaveable,
    dispatch: dispatchOptions,
    loading,
    setLoading,
  } = useOptions();
  const { openErrorModal } = useGenericModals();
  const { changeLanguage } = useLanguage();
  const { editMode } = useEditMode();
  const { t } = useTranslation();
  const { map } = useMapContext();
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
  });
  const { getStorageSize } = useImageUpload();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setLoading(true);

      const { user } = await signIn(form.values.email, form.values.password);
      if (!user) throw Error("no_user");

      try {
        const result = await getAppSubscriptionStatus(
          user.settings?.language ?? navigator.language
        );
        const { errors, state } = result.data;

        if (state === "active") {
          dispatchOptions(
            updateNonSaveableOptions({
              allowPremiumFeatures: true,
            })
          );
        } else if (state === "inGracePeriod") {
          dispatchOptions(
            updateNonSaveableOptions({
              allowPremiumFeatures: true,
            })
          );
          showErrorNotification({
            title: t("errors.subscription_in_grace_period.title"),
            message: t("errors.subscription_in_grace_period.text"),
          });
        } else if (errors && errors.length > 0) {
          captureMessage(
            `Trackbook: Errors when getting info about subscription status: ${errors.join(
              " "
            )}`
          );
        }
      } catch (error) {
        if (axios.isAxiosError(error) && error?.response?.status === 401) {
          signOut();
        } else if (error) {
          captureMessage(error.toString());
        }
      }

      try {
        const size = await getStorageSize();

        dispatchOptions(
          updateNonSaveableOptions({
            storageSize: size,
          })
        );
      } catch (error) {
        captureMessage(
          `Trackbook: Errors when getting storage size: ${(error as AxiosError | Error)?.message}`
        );
      }

      const { settings } = user;
      if (settings) {
        const { autoSaveView, language, ...rest } = settings;
        // (Peter): Validating if map overlay exists here on the frontend, because the backend does no validation, and
        // it can return anything. If overlay is to be renamed, it would still be kept as active overlay on the server
        // IN SHORT: this protects the settings store from being fed invalid data from the server

        if (language) changeLanguage(language, false);
        // TODO: We are sending language here too, but changeLanguage also updates the store - the problem is
        // that there is a race condition with the options reducer (or the hook) and this call would save old language value
        // and overwrite the new one, so as a temporary solution we also pass language here
        updateSaveable({ autoSaveView, language, ...rest }, false);
        if (
          map &&
          settings.viewCenter &&
          autoSaveView &&
          editMode !== "track"
        ) {
          const bounds = L.latLngBounds([settings.viewCenter]);
          map.fitBounds(bounds, { maxZoom: settings.viewZoom, animate: false });
        }
      }
      setLoading(false);
      return null;
    } catch (error) {
      setLoading(false);

      if (axios.isAxiosError(error)) {
        const data = error.response?.data;
        if (data?.err === AuthAPIErrors.EMAIL_NOT_VERIFIED)
          return openVerifyEmailModal("error", data.msg, form.values.email);
        if (data?.err === AuthAPIErrors.NO_ACCOUNT)
          return form.setErrors({ email: data.msg });
        if (data?.err === AuthAPIErrors.INCORRECT_PASSWORD)
          return form.setErrors({ password: data.msg });
        return openErrorModal({ text: data?.msg ?? t("errors.login_failed") });
      }
      return openErrorModal({ text: t("errors.login_failed") });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={4}>
        <TextInput
          autoComplete="email"
          size="xs"
          placeholder="Email"
          required
          {...form.getInputProps("email")}
        />
        <PasswordInput
          autoComplete="current-password"
          size="xs"
          placeholder="Password"
          required
          {...form.getInputProps("password")}
        />
        <Text
          variant="link"
          align="right"
          size="xs"
          sx={{ cursor: "pointer" }}
          onClick={openResetPasswordModal}
        >
          {t("auth.forgot_password_button_label")}
        </Text>
        <Button
          leftIcon={<FontAwesomeIcon icon={faRightToBracket} size="lg" />}
          type="submit"
          size="xs"
          loading={loading}
        >
          {t("auth.login_button_label")}
        </Button>
        <Button
          leftIcon={<FontAwesomeIcon icon={faCircleUser} size="lg" />}
          size="xs"
          sx={{ backgroundColor: SECONDARY_COLOR }}
          onClick={openSignUpModal}
        >
          {t("auth.create_account_label")}
        </Button>
      </Stack>
    </form>
  );
};
