import { useEffect, useMemo, useRef, useState } from "react";

import "Leaflet.Deflate";
import { useMediaQuery } from "@mantine/hooks";
import { CustomTooltip } from "components/LeafletMap/CustomTooltip/CustomTooltip";
import { TRACK_COLORS } from "config/constants";
import { Routing } from "config/enums/routings";
import { usePublicTracks } from "hooks/usePublicTracks";
import { useUserTracks } from "hooks/useUserTracks";
import { PathOptions, Polyline } from "leaflet";
import * as L from "leaflet";
import { nanoid } from "nanoid";
import { useMap } from "react-leaflet";
import { updateNonSaveableOptions } from "stores/optionsStore/actions";
import { useOptions } from "stores/optionsStore/OptionsContext";
import {
  changeRoutingType,
  rewriteComputedTrackpoints,
  rewriteTrackWaypoints,
} from "stores/routingStore/actions";
import { useRouting } from "stores/routingStore/RoutingContext";
import { Coords, TrackWithMetadata } from "types/app";
import { formatDistance } from "utils/formatters/formatDistance";
import { fitPolylineToScreen } from "utils/map/fitToScreen";

import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "components/LeafletMap/MarkerCluster.Custom.css";

type GetPolylineOptions = {
  trackpoints: Coords[];
  color?: PathOptions["color"];
};
const getPolyline = ({ trackpoints, color }: GetPolylineOptions) => {
  if (trackpoints.length === 0) return undefined;
  return L.polyline(trackpoints, {
    color: color ?? TRACK_COLORS.public,
    weight: 4,
    opacity: 1,
    smoothFactor: 1,
  });
};

type TooltipData = {
  polyline: Polyline;
  name: string;
  length: number | undefined;
};

export const UserTracksContainer = () => {
  const { tracks, hiddenTrackIds, selectTrack, selectedTrack } =
    useUserTracks();
  const { tracks: publicTracks, selectTrack: selectPublicTrack } =
    usePublicTracks();
  const {
    dispatch: dispatchOptions,
    state: { isLeftMenuOpen, units },
  } = useOptions();
  const {
    dispatch,
    state: { trackWaypoints },
  } = useRouting();
  const map = useMap();
  const [tooltipsData, setTooltipsData] = useState<TooltipData[]>([]);
  const selectedTrackRef = useRef(selectedTrack);
  useEffect(() => {
    selectedTrackRef.current = selectedTrack;
  }, [selectedTrack]);
  const isSmallViewport = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (!map) return;
    map.createPane("clusterMarkerPane").style.zIndex = "610";
  }, [map]);

  useEffect(() => {
    if (!map || selectedTrackRef.current || trackWaypoints.length !== 0)
      return undefined;
    const markerClusterUserTracks = L.markerClusterGroup({
      showCoverageOnHover: false,
      zoomToBoundsOnClick: true,
      spiderfyOnMaxZoom: false,
      // maxClusterRadius: 80,
      clusterPane: "clusterMarkerPane",
    });

    const deflatedUserTracks = L.deflate({
      minSize: 100,
      markerType: L.circleMarker,
      markerLayer: markerClusterUserTracks,
      // greedyCollapse: false,
    });

    const userTooltipData: TooltipData[] = [];

    if (tracks) {
      const visibileTracks: TrackWithMetadata[] = [];

      for (const track of tracks) {
        let visible = true;
        for (const hiddenTrackId of hiddenTrackIds) {
          if (hiddenTrackId === track.id) {
            visible = false;
            break;
          }
        }
        if (visible) {
          visibileTracks.push(track);
        }
      }

      visibileTracks?.forEach((track) => {
        const polyline = getPolyline({
          trackpoints: track.trackPoints,
          color: track.color,
        });
        if (!polyline) return;
        userTooltipData.push({
          polyline,
          name: track.name,
          length: track.meta?.length,
        });
        polyline.on("click", (e) => {
          L.DomEvent.stopPropagation(e);
          if (isLeftMenuOpen && isSmallViewport) {
            dispatchOptions(
              updateNonSaveableOptions({ isLeftMenuOpen: false })
            );
          }
          selectTrack(track.id);
        });
        // @ts-expect-error Type mismatch between the native Leaflet's polyline.addTo and the Leaflet Deflate addon
        polyline.addTo(deflatedUserTracks);
      });
    }

    const publicTooltipData: TooltipData[] = [];

    if (publicTracks) {
      publicTracks.forEach((track) => {
        const polyline = getPolyline({ trackpoints: track.trackPoints });

        if (!polyline) return;

        publicTooltipData.push({
          polyline,
          name: track.name,
          length: track.meta?.length,
        });
        polyline.on("click", (e) => {
          L.DomEvent.stopPropagation(e);
          selectPublicTrack(track.id);
          if (track.routing === Routing.none) {
            dispatch([
              rewriteTrackWaypoints(track.trackPoints),
              rewriteComputedTrackpoints(track.trackPoints),
              changeRoutingType(Routing.none),
            ]);
          } else {
            dispatch([
              rewriteTrackWaypoints(track.controlPoints),
              changeRoutingType(track.routing as Routing),
            ]);
          }
          fitPolylineToScreen(polyline, map);
        });
        // @ts-expect-error Type mismatch between the native Leaflet's polyline.addTo and the Leaflet Deflate addon
        polyline.addTo(deflatedUserTracks);
      });
    }
    setTooltipsData([...userTooltipData, ...publicTooltipData]);

    deflatedUserTracks.addTo(map);

    return () => {
      deflatedUserTracks.removeFrom(map);
    };
  }, [
    dispatch,
    dispatchOptions,
    isLeftMenuOpen,
    isSmallViewport,
    map,
    publicTracks,
    selectTrack,
    selectPublicTrack,
    trackWaypoints.length,
    tracks,
    hiddenTrackIds,
  ]);

  const tooltips = useMemo(
    () =>
      tooltipsData.map((data) => (
        <CustomTooltip
          key={nanoid()}
          polyline={data.polyline}
          html={`<div style="text-align:center"><div>${data.name}</div><div>
          ${
            data.length ? formatDistance(data.length * 1000, units, 1) : ""
          }</div></div>`}
        />
      )),
    [tooltipsData, units]
  );
  return useMemo(
    () =>
      tooltips.length > 0 && !selectedTrackRef.current ? <>{tooltips}</> : null,
    [tooltips]
  );
};
