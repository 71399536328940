import { Box } from '@mantine/core'
import { useGenericState } from 'stores/genericStore/GenericContext'
import { useRouting } from 'stores/routingStore/RoutingContext';
import { useMediaQuery } from "@mantine/hooks";

export const VerticalBar = () => {
  const { chartTooltipData } = useGenericState()
    const isSmallViewport = useMediaQuery("(max-width: 768px)");
    const {
      state: { trackWaypoints },
    } = useRouting();

  return chartTooltipData &&
  ((!isSmallViewport && chartTooltipData?.isHovered) ||
    (isSmallViewport && trackWaypoints.length > 1)) ? (
    <div style={{ position: 'absolute', left: chartTooltipData.left, top: 0, bottom: 0 }}>
      <Box
        sx={(theme) => ({
          position: 'absolute',
          top: 26,
          bottom: 34,
          borderLeftStyle: 'dashed',
          borderLeftWidth: 1,
          borderLeftColor: theme.colors.gray[7],
          pointerEvents: 'none',
        })}
      ></Box>
    </div>
  ) : null
}
