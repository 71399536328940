import { faFileExport } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'components/UI/Tooltip'
import { saveAs } from 'file-saver'
import { useGenericModals } from 'hooks/useGenericModals'
import { useUserTracks } from 'hooks/useUserTracks'
import { useUserWaypoints } from 'hooks/useUserWaypoints'
import { useTranslation } from 'react-i18next'
import { useRouting } from 'stores/routingStore/RoutingContext'
import { encodeGPX } from 'utils/gpx/encodeGPX'
import { getSafeName } from 'utils/helpers/exportGPX/getExportSafeName'
import { showTrackExportSuccessNotification } from 'utils/notifications/trackNotifications'

import { CollapsibleTopMenuActionIcon } from '../../../../components/UI/TopMenu/CollapsibleActionIcon'

export const ExportTrackIcon = ({ isOpen }: { isOpen: boolean }) => {
  const {
    state: { computedTrackpoints, elevations },
  } = useRouting()
  const { selectedTrack } = useUserTracks()
  const { selectedWaypoint } = useUserWaypoints()
  const { openErrorModal } = useGenericModals()
  const { t } = useTranslation()

  const encodeWaypoint = () => {
    if (!selectedWaypoint) return undefined
    return encodeGPX({
      waypoints: [
        {
          name: selectedWaypoint.name,
          desc: selectedWaypoint.desc,
          icon: selectedWaypoint.icon,
          links: selectedWaypoint.links,
          id: selectedWaypoint.id,
          userId: selectedWaypoint.userId,
          point: selectedWaypoint.point,
          ele: selectedWaypoint.ele,
          localTime: selectedWaypoint.localTime,
          createdAt: selectedWaypoint.createdAt,
          updatedAt: selectedWaypoint.updatedAt,
          cmt: selectedWaypoint.cmt,
          src: selectedWaypoint.src,
          type: selectedWaypoint.type,
          visible: true,
        },
      ],
    })
  }

  const encodeUnsavedTrack = () =>
    encodeGPX({
      tracks: [
        {
          name: 'Track',
          trackPoints: computedTrackpoints,
          trackPointsEle: elevations,
        },
      ],
    })

  const encodeSavedTrack = () => {
    if (!selectedTrack) return undefined
    return encodeGPX({
      tracks: [
        {
          description: selectedTrack.desc,
          name: selectedTrack.name,
          trackPoints: selectedTrack.trackPoints,
          trackPointsEle: selectedTrack.trackPointsEle,
          links: selectedTrack.links,
        },
      ],
    })
  }

  const exportToGpx = () => {
    if (selectedWaypoint) {
      try {
        const encoded = encodeWaypoint()
        if (!encoded) throw new Error()
        const blob = new Blob([encoded], { type: 'application/gpx+xml' })
        saveAs(blob, `${getSafeName(selectedWaypoint.name)}.gpx`)
        showTrackExportSuccessNotification({ name: selectedWaypoint?.name || 'Waypoint' })
      } catch (error) {
        openErrorModal({ title: t('generic.error_title'), text: t('errors.encoding_GPX_failed.text') })
      }
    } else {
      try {
        const encoded = selectedTrack ? encodeSavedTrack() : encodeUnsavedTrack()
        if (!encoded) throw new Error()
        const blob = new Blob([encoded], { type: 'application/gpx+xml' })
        saveAs(blob, `${getSafeName(selectedTrack?.name)}.gpx`)
        showTrackExportSuccessNotification({ name: selectedTrack?.name || 'Track' })
      } catch (error) {
        openErrorModal({ title: t('generic.error_title'), text: t('errors.encoding_GPX_failed.text') })
      }
    }
  }

  return (
    <Tooltip label={t('editmode.export_to_gpx_button_tooltip')}>
      <div>
        <CollapsibleTopMenuActionIcon
          isOpen={isOpen}
          disabled={computedTrackpoints.length < 2 && !selectedWaypoint}
          onClick={exportToGpx}
        >
          <FontAwesomeIcon icon={faFileExport} />
        </CollapsibleTopMenuActionIcon>
      </div>
    </Tooltip>
  )
}
