import React, { useState } from "react";
import { Box, Group, Stack, useMantineTheme } from "@mantine/core";
import { useAuth } from "hooks/useAuth";
import { useInitialModals } from "hooks/useInitialModals";
import { ChartPanel } from "./UI/ChartPanel/ChartPanel";
import { AuthenticatedUserMenu } from "./UI/LeftMenu/AuthenticatedUserMenu";
import { UnauthenticatedUserMenu } from "./UI/LeftMenu/Unauthenticated/UnauthenticatedUserMenu";
import { LegendPanel } from "./UI/LegendPanel/LegendPanel";
import { TopEditMenu } from "./UI/TopEditMenu/TopEditMenu";
import { LeftMenuWrapper } from "./UI/Wrappers/LeftMenuWrapper";
import { MapWrapper } from "./UI/Wrappers/MapWrapper";
import { useMediaQuery } from "usehooks-ts";
import { useMobileMenu } from "hooks/useMobileMenu";
import { PANEL_TOGGLE_ANIMATION_DURATION } from "config/constants";

import "./MainContainer.css";

function NavbarItem({
  text,
  link,
  active = false,
}: {
  text: string;
  link: string;
  active?: boolean;
}) {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useMantineTheme();
  const itemFontColor = theme.colorScheme === "dark" ? "#fff" : "#000";
  const itemBgColor =
    theme.colorScheme === "dark" ? theme.colors.dark[8] : "#fff";
  const itemActiveColor = "#7950f2";

  return (
    <li
      className="item"
      role="none"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <a
        role="menuitem"
        style={{
          color: active ? "#fff" : itemFontColor,
          borderColor: isHovered
            ? itemActiveColor
            : active
            ? itemActiveColor
            : itemBgColor,
          backgroundColor: active ? itemActiveColor : "inherit",
        }}
        href={link}
      >
        {text}
      </a>
    </li>
  );
}

function Navbar() {
  const isSmallViewport = useMediaQuery("(max-width: 768px)");
  const theme = useMantineTheme();

  return !isSmallViewport ? (
    <nav
      className="navbar"
      style={{
        backgroundColor:
          theme.colorScheme === "dark" ? theme.colors.dark[8] : "#fff",
      }}
    >
      <ul className="header-menu" role="menubar">
        <NavbarItem link="https://vecturagames.com" text="Home" />
        <NavbarItem link="https://blog.vecturagames.com" text="Blog" />
        <NavbarItem link="https://gpxviewer.com" text="GPX Viewer" />
        <NavbarItem link="https://trackbook.online" text="Trackbook" active />
      </ul>
    </nav>
  ) : null;
}

function MobileMenuItem({
  text,
  link,
  active = false,
}: {
  text: string;
  link: string;
  active?: boolean;
}) {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useMantineTheme();
  const itemFontColor = theme.colorScheme === "dark" ? "#fff" : "#000";
  const itemActiveColor = "#7950f2";

  return (
    <li
      role="none"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <a
        role="menuitem"
        href={link}
        style={{
          color: active
            ? itemActiveColor
            : isHovered
            ? itemActiveColor
            : itemFontColor,
        }}
      >
        {text}
      </a>
    </li>
  );
}

function MobileMenu() {
  const [isXHovered, setIsXHovered] = useState(false);
  const { isMobileMenuOpen, toggleMobileMenu } = useMobileMenu();
  const theme = useMantineTheme();

  return (
    <nav
      className="mobile-menu"
      style={{
        height: 190,
        marginTop: isMobileMenuOpen ? 0 : -190,
        transition: `margin-top ${PANEL_TOGGLE_ANIMATION_DURATION}s`,
        backgroundColor:
          theme.colorScheme === "dark" ? theme.colors.dark[8] : "#fff",
      }}
    >
      <ul role="menubar">
        <MobileMenuItem link="https://vecturagames.com" text="Home" />
        <MobileMenuItem link="https://blog.vecturagames.com" text="Blog" />
        <MobileMenuItem
          link="https://vecturagames.com/gpxviewer/"
          text="GPX Viewer"
        />
        <MobileMenuItem link="#" text="Trackbook" active />
      </ul>
      <a
        className="x-icon-btn"
        type="button"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={() => toggleMobileMenu()}
      >
        <i
          className="fa fa-x"
          onMouseEnter={() => setIsXHovered(true)}
          onMouseLeave={() => setIsXHovered(false)}
          style={{
            color: isXHovered
              ? "#7950f2"
              : theme.colorScheme === "dark"
              ? "#fff"
              : "#000",
            backgroundColor:
              theme.colorScheme === "dark" ? theme.colors.dark[8] : "#fff",
          }}
          title="Toggle navigation"
        ></i>
      </a>
    </nav>
  );
}

export const MainContainer = () => {
  const { user } = useAuth();

  useInitialModals(user);

  return (
    <Box sx={{ width: "100%", height: "100%", position: "absolute" }}>
      <Navbar />
      <Group
        sx={{
          height: "100%",
          width: "100%",
          flexWrap: "nowrap",
          overflow: "hidden",
        }}
        spacing={0}
      >
        <MobileMenu />
        <LeftMenuWrapper>
          {user ? <AuthenticatedUserMenu /> : <UnauthenticatedUserMenu />}
          {/* <TabMenu /> */}
        </LeftMenuWrapper>
        <Stack
          id="mapchartwrapper"
          sx={{
            height: "100%",
            flexGrow: 1,
            minWidth: 0,
            overflow: "hidden",
            position: "relative",
          }}
          spacing={0}
        >
          <MapWrapper />
          <ChartPanel />
          <TopEditMenu />
        </Stack>
        <LegendPanel />
        {/* {isDev() && <DebugPanel />} */}
      </Group>
    </Box>
  );
};
