//import React, { useRef } from "react";

import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Anchor,
  Button,
  Checkbox,
  PasswordInput,
  Stack,
  TextInput,
  useMantineColorScheme,
  //useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { ContextModalProps } from "@mantine/modals";
//import axios from "axios";
import { useAuth } from "hooks/useAuth";
import { useAuthModals } from "hooks/useAuthModals";
import { useGenericModals } from "hooks/useGenericModals";
//import ReCAPTCHA from "react-google-recaptcha";
import { Trans, useTranslation } from "react-i18next";
//import { getErrorMsg } from "utils/getErrorMsg/getErrorMsg";

//const BASE_API_URL = "https://api.vecturagames.com/public";
//const { VITE_SITE_KEY } = import.meta.env;

export const SignUpModal = ({ context, id }: ContextModalProps) => {
  const { openVerifyEmailModal } = useAuthModals();
  const { openErrorModal } = useGenericModals();
  const { signUp, loading } = useAuth();
  const { colorScheme } = useMantineColorScheme();
  const { t } = useTranslation();
  //const theme = useMantineTheme();
  //const captchaRef = useRef<ReCAPTCHA | null>(null);
  const form = useForm({
    initialValues: {
      email: "",
      password: "",
      passwordrepeat: "",
      termsOfService: false,
      receiveNewsletter: false,
    },
  });

  const handleSignUpSuccess = (message: string) => {
    context.closeModal(id);
    openVerifyEmailModal("initial", message);
  };

  /*const verifyToken = async (
    token: string | null | undefined
  ) => {
    try {
      return await axios.post<{ code: number }>(`${BASE_API_URL}/recaptcha`, {
        token,
      });
    } catch (error) {
      if (error) {
        openErrorModal({
          title: t("generic.error_title"),
          text: getErrorMsg(error),
        });
      }
    }
    return
  };*/

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    //const token = captchaRef?.current?.getValue();
    //captchaRef?.current?.reset();

    //const result = await verifyToken(token);

    //if (result?.data && result.data.code === 0) {
    const { success, errors, error } = await signUp(
      form.values.email,
      form.values.password,
      form.values.passwordrepeat,
      form.values.receiveNewsletter,
      colorScheme
    );

    if (errors) {
      form.setErrors(errors);
    }
    if (error) {
      openErrorModal({
        title: t("generic.error_title"),
        text: error,
      });
    }
    if (success) {
      handleSignUpSuccess(success);
    }
    //}
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing="sm">
        <TextInput
          data-autofocus
          disabled={loading}
          label={t("signup_modal.email.label")}
          placeholder={t("signup_modal.email.placeholder")}
          icon={<FontAwesomeIcon icon={faEnvelope} />}
          required
          {...form.getInputProps("email")}
        />
        <PasswordInput
          disabled={loading}
          label={t("signup_modal.password.label")}
          placeholder={t("signup_modal.password.placeholder")}
          icon={<FontAwesomeIcon icon={faLock} />}
          required
          {...form.getInputProps("password")}
        />
        <PasswordInput
          disabled={loading}
          label={t("signup_modal.confirm_password.label")}
          placeholder={t("signup_modal.confirm_password.placeholder")}
          icon={<FontAwesomeIcon icon={faLock} />}
          required
          {...form.getInputProps("passwordrepeat")}
        />
        <Checkbox
          mt="sm"
          disabled={loading}
          label={t("signup_modal.newsletter.label")}
          {...form.getInputProps("receiveNewsletter", { type: "checkbox" })}
        />
        <Checkbox
          mb="sm"
          disabled={loading}
          required
          label={
            <>
              <Trans t={t} i18nKey="signup_modal.privacy_policy.label">
                {"I confirm that I have read, consent and agree to the"}{" "}
                <Anchor
                  href="https://vecturagames.com/privacy_trackbook/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {"Trackbook Privacy Policy"}
                </Anchor>
                {"."}
              </Trans>
            </>
          }
          {...form.getInputProps("termsOfService", { type: "checkbox" })}
        />
        {/*<ReCAPTCHA
          sitekey={VITE_SITE_KEY}
          ref={captchaRef}
          theme={theme.colorScheme}
        />*/}
        <Button type="submit" loading={loading} fullWidth>
          {t("signup_modal.submit_button_text")}
        </Button>
      </Stack>
    </form>
  );
};
