import { useLayoutEffect, useRef } from 'react'

import 'pelias-leaflet-plugin'
import 'pelias-leaflet-plugin/dist/leaflet-geocoder-mapzen.min.css'
import './StyleReset.css'
import { createStyles } from '@mantine/core'
import { defaultIcon } from 'components/LeafletMap/Icons'
import L, { Control } from 'leaflet'
import { useMap } from 'react-leaflet'

const GEOCODER_OPTIONS: L.Control.GeocoderOptions = {
  url: 'https://geo.vecturagames.com/v1',
  placeholder: 'Search',
  expanded: false,
  autocomplete: false,
  markers: {
    icon: defaultIcon,
  },
  layers: [
    'venue', // points of interest, businesses, things with walls
    'address', // places with a street address
    'country', // places that issue passports, nations, nation-states
    'macroregion', // a related group of regions. Mostly in Europe
    'region', // states and provinces
    'macrocounty', // a related group of counties. Mostly in Europe.
    'county', // official governmental area; usually bigger than a locality, almost always smaller than a region
    'locality', // towns, hamlets, cities
    'neighbourhood', // social communities, neighbourhoods
    // "street", // streets,roads,highways
    // "localadmin", // local administrative boundaries
    // "borough", // a local administrative boundary, currently only used for New York City
    // "coarse", // alias for simultaneously using all administrative layers (everything except venue and address)
    // "postalcode" // postal code used by mail services
    // "macrohood",
    // "marinearea",
    // "disputed",
    // "dependency",
    // "empire",
    // "continent",
    // "ocean"
  ],
  attribution: 'Geocoding by <a href="https://mapzen.com/projects/search/">Mapzen</a>',
}

const useStyles = createStyles((theme) => {
  const isDark = theme.colorScheme === 'dark'
  const backgroundColor = `${isDark ? theme.colors.dark[7] : 'white'}!important`
  const color = `${isDark ? theme.colors.dark[0] : theme.colors.gray[9]}!important`

  return {
    customSearchControl: {
      backgroundColor,
      boxShadow: `${theme.other.controlButtonShadow}!important`,
    },
    icon: {
      // using filter to change color of the default background img
      filter: isDark ? 'invert(0.5) brightness(1.7)' : '',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },
    input: {
      color,
    },
    close: { color },
    results: {
      color,
      backgroundColor,
      boxShadow: `${theme.shadows.xs}!important`,
      borderWidth: '1px!important',
      borderStyle: 'solid!important',
      borderColor: `${isDark ? theme.colors.dark[4] : theme.colors.gray[4]}!important`,
    },
  }
})

export const SearchGeocoderControl = ({ position }: { position: L.PositionString }) => {
  const { classes } = useStyles()
  const geocoder = useRef<Control | null>(null)
  const map = useMap()

  useLayoutEffect(() => {
    geocoder.current = L.control.geocoder('', { ...GEOCODER_OPTIONS, position })
    map.addControl(geocoder.current)

    // https://github.com/skorasaurus/marillac/commit/87a7023bd3b499a6349be62be4e8e34401be6191#diff-2126216ecd2ff9ae9b5f0c1e84d8fecb4ee4e54dc21685f69a590c2162dec186R488
    // @ts-expect-error The typings are not extended correctly for this
    geocoder.current.on('select', (e) => {
      map.setView(e.latlng, 17);
    });

    return () => {
      if (geocoder.current) {
        map.removeControl(geocoder.current)
      }
    }
  }, [map, position])

  useLayoutEffect(() => {
    if (!geocoder.current) return undefined
    const container = geocoder.current.getContainer()
    const input = container?.querySelector('input')
    const icon = container?.querySelector('a')
    const closeIcon = container?.querySelector('.leaflet-pelias-close')
    const results = container?.querySelector('.leaflet-pelias-results')

    container?.classList.add(classes.customSearchControl)
    icon?.classList.add(classes.icon)
    input?.classList.add(classes.input)
    closeIcon?.classList.add(classes.close)
    results?.classList.add(classes.results)

    return () => {
      container?.classList.remove(classes.customSearchControl)
      icon?.classList.remove(classes.icon)
      input?.classList.remove(classes.input)
      closeIcon?.classList.remove(classes.close)
      results?.classList.remove(classes.results)
    }
  }, [classes])

  return null
}
