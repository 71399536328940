import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  faArrowDownAZ,
  faArrowDownShortWide,
  faArrowUpAZ,
  faArrowUpShortWide,
} from "@fortawesome/free-solid-svg-icons";
import { Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  ItemMenu,
  ItemMenuData,
  MultiSelectAction,
} from "components/UI/ItemMenu/ItemMenu";
import { saveAs } from "file-saver";
import { useGenericModals } from "hooks/useGenericModals";
import { useUserWaypoints } from "hooks/useUserWaypoints";
import { TFunction, useTranslation } from "react-i18next";
import { updateNonSaveableOptions } from "stores/optionsStore/actions";
import { useOptions } from "stores/optionsStore/OptionsContext";
import { formatCoords } from "utils/formatters/formatCoords";
import { encodeGPX } from "utils/gpx/encodeGPX";
import { getSafeName } from "utils/helpers/exportGPX/getExportSafeName";
import { filterDataByString } from "utils/helpers/filterData";
import { getWaypointIconSrc } from "utils/helpers/icons/getWaypointIconName";
import { showWaypointExportSuccessNotification } from "utils/notifications/waypointNotifications";
import { FaLocationDotPlus } from "assets/icons/FaLocationDotPlus";
import {
  SortByPicker,
  SortingOptions,
} from "../../../components/UI/SortByPicker";
import TracksWaypointsMenuPlaceholder from "./TracksWaypointsMenuPlaceholder";

export type WaypointsSortByOptions = "createdDate" | "name";

const getSortingOptions = (
  t: TFunction
): SortingOptions<WaypointsSortByOptions>[] => [
  {
    value: "name",
    label: t("sorting.name.AZ"),
    icon: faArrowDownAZ,
    direction: "asc",
  },
  {
    value: "name",
    label: t("sorting.name.ZA"),
    icon: faArrowUpAZ,
    direction: "desc",
  },
  {
    value: "createdDate",
    label: t("sorting.date.from_latest"),
    icon: faArrowDownShortWide,
    direction: "desc",
  },
  {
    value: "createdDate",
    label: t("sorting.date.from_oldest"),
    icon: faArrowUpShortWide,
    direction: "asc",
  },
];

type SortDataOptions = {
  sortBy: WaypointsSortByOptions;
  data: ItemMenuData[] | undefined;
  ascending: boolean;
};

const sortData = ({ sortBy, data, ascending }: SortDataOptions) => {
  if (!data) return undefined;
  let sorted = data;
  if (sortBy === "name") {
    sorted = [...data].sort((a, b) => a.name.localeCompare(b.name));
  }
  return ascending ? sorted : sorted.reverse();
};

export const WaypointsMenu = () => {
  const {
    waypoints,
    hiddenWaypointIds,
    selectWaypoint,
    selectedWaypoint,
    isFetching,
    deleteWaypoints,
    toggleWaypointVisibility,
  } = useUserWaypoints();
  const {
    dispatch,
    state: { isLeftMenuOpen, sortWaypoints },
    updateSaveable,
  } = useOptions();
  const [filterKeyword, setFilterKeyword] = useState("");
  const selectedWaypointRef = useRef(selectedWaypoint);
  const { openErrorModal, openConfirmWaypointDeleteModal } = useGenericModals();
  const { t } = useTranslation();
  const waypointsRef = useRef(waypoints);

  useEffect(() => {
    waypointsRef.current = waypoints;
  }, [waypoints]);

  useEffect(() => {
    selectedWaypointRef.current = selectedWaypoint;
  }, [selectedWaypoint]);

  const isSmallViewport = useMediaQuery("(max-width: 768px)");

  // Currently unused
  // const getWaypointById = (id: number) =>
  //   waypointsRef.current?.find((value) => value.id === id);

  const handleMenuItemSelect = useCallback(
    (id: number) => {
      if (id !== selectedWaypointRef.current?.id) {
        if (isLeftMenuOpen && isSmallViewport) {
          dispatch(updateNonSaveableOptions({ isLeftMenuOpen: false }));
        }
        selectWaypoint(id, { focus: true });
      } else {
        selectWaypoint(null);
      }
    },
    [dispatch, isLeftMenuOpen, isSmallViewport, selectWaypoint]
  );

  const exportToGPX: MultiSelectAction = useCallback(
    (ids, callback) => {
      if (!waypointsRef.current) return;
      const items = waypointsRef.current.filter((item) =>
        ids.includes(item.id)
      );
      const encoded = encodeGPX({
        waypoints: items,
      });
      try {
        if (!encoded) throw new Error();
        const blob = new Blob([encoded], { type: "application/gpx+xml" });
        if (items.length > 1) {
          saveAs(blob, "export.gpx");
        } else {
          saveAs(blob, `${getSafeName(items[0].name)}.gpx`);
        }
        showWaypointExportSuccessNotification({ count: items.length });
        callback();
      } catch (error) {
        openErrorModal({
          title: t("generic.error_title"),
          text: t("errors.encoding_GPX_failed.text"),
        });
      }
    },
    [openErrorModal, t]
  );

  const deleteItems: MultiSelectAction = useCallback(
    (ids, callback) => {
      openConfirmWaypointDeleteModal({
        count: ids.length,
        onConfirm: async () => {
          await deleteWaypoints(ids);
          callback();
        },
      });
    },
    [deleteWaypoints, openConfirmWaypointDeleteModal]
  );

  const handleMenuItemRightButtonClick = useCallback(
    (id: number) => {
      toggleWaypointVisibility(id);
    },
    [toggleWaypointVisibility]
  );

  const data = waypoints?.map((waypoint) => {
    const itemData: ItemMenuData = {
      ...waypoint,
      bottomInfo: formatCoords(waypoint.point),
      leftImageSrc: getWaypointIconSrc(waypoint.icon || "Default"),
    };
    return itemData;
  });

  const filteredData = filterDataByString(data, "name", filterKeyword);

  const itemData = sortData({
    data: filteredData,
    sortBy: sortWaypoints.type,
    ascending: sortWaypoints.direction === "asc",
  });

  const CurrentSortByPicker = useMemo(
    () => (
      <SortByPicker
        data={getSortingOptions(t)}
        value={sortWaypoints.type}
        sortDirection={sortWaypoints.direction}
        onItemChange={(value, direction) => {
          updateSaveable({ sortWaypoints: { type: value, direction } });
        }}
      />
    ),
    [sortWaypoints.direction, sortWaypoints.type, t, updateSaveable]
  );

  const handleMultiSelectModeOn = useCallback(() => {
    selectWaypoint(null);
  }, [selectWaypoint]);

  return useMemo(
    () => (
      <>
        <ItemMenu
          data={itemData}
          selectedItemId={selectedWaypoint?.id}
          hiddenItemIds={hiddenWaypointIds}
          onItemClick={handleMenuItemSelect}
          onItemRightButtonClick={handleMenuItemRightButtonClick}
          loading={isFetching}
          placeholder={
            <TracksWaypointsMenuPlaceholder
              emptyText={
                <Text
                  p="md"
                  color="dimmed"
                  size="sm"
                  align="center"
                  sx={{ userSelect: "none" }}
                >
                  {t("waypoints_menu.help_text_01")}
                  {t("waypoints_menu.help_text_02a")} <FaLocationDotPlus color="#7950f2" /> {t("waypoints_menu.help_text_02b")}
                  {t("waypoints_menu.help_text_03")}
                  {t("waypoints_menu.help_text_04")}
                </Text>
              }
            />
          }
          onExportToGPXclick={exportToGPX}
          onDeleteClick={deleteItems}
          onMultiSelectModeOn={handleMultiSelectModeOn}
          onFilterInput={setFilterKeyword}
          topSubmenuComponent={CurrentSortByPicker}
        />
      </>
    ),
    [
      t,
      CurrentSortByPicker,
      deleteItems,
      exportToGPX,
      handleMenuItemRightButtonClick,
      handleMenuItemSelect,
      handleMultiSelectModeOn,
      isFetching,
      itemData,
      selectedWaypoint?.id,
    ]
  );
};
