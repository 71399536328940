import { MAX_ZOOMS } from 'config/constants'
import { MapID } from 'config/enums/mapIDs'
import { TileLayer } from 'react-leaflet'

type RasterLayerMeta = {
  id: number
  label: string
  url: string
  attribution: string
}

export const RASTER_LAYERS_META: RasterLayerMeta[] = [
  {
    id: MapID.openStreetMap,
    label: 'OpenStreetMap',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  },
  {
    id: MapID.openTopoMap,
    label: 'OpenTopoMap',
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="https://opentopomap.org/about">OpenTopoMap</a>',
  },
  {
    id: MapID.cyclOSM,
    label: 'CyclOSM',
    url: 'https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="https://www.cyclosm.org/legend.html">CyclOSM</a>',
  },
  {
    id: MapID.satellite,
    label: 'Satellite',
    url: 'https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?style=explore.satellite.day&size=512&apiKey=5vMCWygbMZGe1nO78iLqtzweBG_0KtzlbtqZaKrMaOs',
    attribution: '&copy; HERE, DigitalGlobe',
  },
]

export const RASTER_LAYERS_LABELS = RASTER_LAYERS_META.map((layer) => ({ label: layer.label, value: layer.id }))

export const getRasterLayer = (id: number) => {
  const meta = RASTER_LAYERS_META.find((layer) => layer.id === id)
  if (!meta) return null
  return (
    <TileLayer
      maxZoom={MAX_ZOOMS.raster}
      maxNativeZoom={18}
      key={meta.label}
      url={meta.url}
      attribution={meta.attribution}
    />
  )
}
